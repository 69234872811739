<template>
  <li class="header-navigation_item" :class="{'mobile-cart': isMobileCart}" @click.prevent="showHelpModal">
    <a href="#" class="header-navigation_link">
      <img v-show="$store.state.device.isMobile" src="/img/mobile-menu/help.svg" alt="help" class="mr-3 header-navigation_i">
      <!-- <font-awesome-icon icon="question-circle" class="show-mobile header-navigation_i"/> -->
      <span class="text">Help</span>
    </a>
    <v-dialog v-model="helpModal" width="550">
      <v-card>
        <div class="dialog-header p-16 text-center">
          <span class="dialog-header_title lighten-3">Get help</span>
        </div>

        <div v-if="newQuestion">
          <div class="p-16-20">
            <p class="text--center text__15">We are here to help you! Find the ansver to your question in few clicks</p>
          </div>
          <div class="p-16-20">
            <div class="help-step">
              <span class="help-step-icon">2</span>
              <span>Please provide next info about your reservation</span>
            </div>
            <div class="new-help-form">
              <div>
                <label class="default-label">Reservation Number</label>
                <v-text-field solo type="text" autocomplete="new-email"></v-text-field>
              </div>
              <div>
                <label class="default-label">PIN Code</label>
                <v-text-field solo type="text" autocomplete="new-email"></v-text-field>
              </div>
            </div>
            <div class="text--right">
              <v-btn color="primary" depressed outlined @click="helpModal = false">Cancel</v-btn>
              <v-btn color="primary" depressed @click="goToHelpForm">Send</v-btn>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="p-16-20">
            <p class="text--center text__15">We are here to help you! Find the ansver to your question in few clicks</p>
          </div>
          <div class="p-16-20">
            <div class="help-step">
              <span class="help-step-icon">1</span>
              <span>Do you need a help with existing servation?</span>
            </div>
          </div>
          <div class="p-16-20 text--right">
            <v-btn class="mobile-full-width m_b-16" color="primary" depressed outlined large @click="showHelpForm">
              No, I have another question
            </v-btn>
            <v-btn class="mobile-full-width m_b-16" color="primary" depressed large @click="goToHelp">
              Yes, please
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </li>
</template>

<script>
export default {
  props: ['isMobileCart'],
  data() {
    return {
      helpModal: false,
      newQuestion: false,
      timer: undefined,
      helpContainer: null
    }
  },
  methods: {
    showHelpModal() {
      if (!this.helpContainer) {
        this.$store.commit('SET_GLOBAL_LOADING', true)
      }

      const LiveChatSRC = require('../../plugins/live-chat.js')
      if (typeof LiveChatWidget != 'undefined') {
        LiveChatWidget.call('maximize')
      } else {
        let LiveChat = document.createElement('script')
        LiveChat.setAttribute('src', LiveChatSRC)
        LiveChatWidget.call('maximize')
      }

      this.timer = setInterval(() => {
        this.checkHelpModal()
      }, 500)
    },
    checkHelpModal() {
      this.helpContainer = document.getElementById('chat-widget-container')
      if (this.helpContainer) {
        this.$store.commit('SET_GLOBAL_LOADING', false)
        clearInterval(this.timer)
      }
    },
    goToHelp() {
      this.helpModal = false
      this.$router.push('/help')
    },
    goToHelpForm() {
      this.helpModal = false
      this.$router.push('/help/new-question')
    },
    showHelpForm() {
      this.newQuestion = true
    },
  },
  computed: {
    seenModal() {
      return this.helpModal
    }
  },
  watch: {
    seenModal(status) {
      if (!status) {
        this.newQuestion = false
      }
    }
  }
}
</script>

<style>
.help-step {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.help-step-icon {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  color: #000000;
  background: #FFB800;
  border-radius: 5px;
  padding: 2px 9px;
}

.new-help-form {
  margin: 20px auto;
  width: 300px;
  max-width: 100%;
}
@media screen and (max-width: 768px) {
  .help-step {
    position: relative;
    padding-left: 36px;
    text-align: left;
  }

  .help-step-icon {
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
