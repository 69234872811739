<template>
  <div class="header-mobile-menu">
    <div class="header-mobile-menu__wrapper">
      <v-text-field
        label="Search"
        placeholder="Search"
        outlined
      >
      <template v-slot:v-slot:prepend-inner>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.7535 15.5622L14.2485 12.0574C14.0903 11.8992 13.8758 11.8113 13.6508 11.8113H13.0778C14.0481 10.5705 14.6246 9.00967 14.6246 7.31179C14.6246 3.27273 11.3517 0 7.31232 0C3.27297 0 0 3.27273 0 7.31179C0 11.3508 3.27297 14.6236 7.31232 14.6236C9.01033 14.6236 10.5712 14.0471 11.8122 13.0768V13.6498C11.8122 13.8748 11.9001 14.0892 12.0583 14.2474L15.5633 17.7522C15.8938 18.0826 16.4281 18.0826 16.7551 17.7522L17.75 16.7573C18.0804 16.4269 18.0804 15.8926 17.7535 15.5622ZM7.31232 11.8113C4.82684 11.8113 2.81243 9.80061 2.81243 7.31179C2.81243 4.82648 4.82332 2.81223 7.31232 2.81223C9.79781 2.81223 11.8122 4.82297 11.8122 7.31179C11.8122 9.79709 9.80132 11.8113 7.31232 11.8113Z" fill="#879EAF"/>
          </svg>

      </template>
      </v-text-field>
      <!-- <v-list dense>
        <v-list-item-group
          v-model="selectedItem"
          color="primary"
        >
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            :to="item.to || '#'"
            @click="checkClick(item)"
          >
            <v-list-item-icon>
              <img :src='`/img/mobile-menu/${item.icon}.svg`' :alt="item.text">
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list> -->
      <v-menu v-if="!userData" class="d-block">
        <template v-slot:activator="{ on }">
          <v-list dense >
            <!-- <v-subheader>REPORTS</v-subheader> -->
            <v-list-item-group
              v-model="selectedItem"
              color="primary"
            >
              <v-list-item
                v-for="(item, i) in itemsNotSignin"
                :key="i"
                :to="item.to || '#'"
                @click="checkClick(item)"
              >
                <v-list-item-icon>
                  <!-- <v-icon v-text="item.icon"></v-icon> -->
                  <img :src='`/img/mobile-menu/${item.icon}.svg`' :alt="item.text">
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </template>

        <v-list dense v-if="userData">
          <!-- <v-subheader>REPORTS</v-subheader> -->
          <v-list-item-group
            v-model="selectedItem2"
            color="primary"
          >
            <v-list-item
              v-for="(item, i) in itemsSignin"
              :key="i"
              :to="item.to"
              @click="checkClick(item)"
            >
              <v-list-item-icon>
                <!-- <v-icon v-text="item.icon"></v-icon> -->
                <img :src='`/img/mobile-menu/${item.icon}.svg`' :alt="item.text">
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-menu v-else class="d-block">
        <v-list>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>

            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-group
            :value="true"
            prepend-icon="mdi-account-circle"
          >
            <template v-slot:activator>
              <v-list-item-title>Users</v-list-item-title>
            </template>

            <v-list-group
              :value="true"
              no-action
              sub-group
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Admin</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="([title, icon], i) in admins"
                :key="i"
                link
              >
                <v-list-item-title v-text="title"></v-list-item-title>

                <v-list-item-icon>
                  <v-icon v-text="icon"></v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>

            <v-list-group
              no-action
              sub-group
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Actions</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="([title, icon], i) in cruds"
                :key="i"
                link
              >
                <v-list-item-title v-text="title"></v-list-item-title>

                <v-list-item-icon>
                  <v-icon v-text="icon"></v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>
          </v-list-group>
        </v-list>
      </v-menu>
      <div class="select">$ USD
        <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.230089 1.38649L0.254222 1.36573L0.893287 0.728564L0.896704 0.725327C1.02872 0.600291 1.24022 0.508357 1.45809 0.500551C1.65542 0.493483 1.8432 0.553723 2.00011 0.720951L2.01818 0.740208L8.01652 6.39046L13.9815 0.740565L13.9991 0.721745C14.1562 0.553952 14.3441 0.49357 14.5415 0.500535C14.7595 0.50823 14.9712 0.60019 15.1033 0.725327L15.1067 0.728538L15.7458 1.36573L15.7699 1.38649C15.9418 1.53436 16.008 1.71568 15.9992 1.91073C15.9897 2.12322 15.8898 2.32553 15.7583 2.45001L8.55284 9.27487C8.40209 9.41766 8.20718 9.5 8 9.5C7.79282 9.5 7.59791 9.41766 7.44716 9.27487L0.241659 2.45001C0.110229 2.32553 0.0102932 2.12322 0.000760021 1.91073C-0.00799122 1.71568 0.0582066 1.53436 0.230089 1.38649Z" fill="black"/>
        </svg>
      </div>
      <div class="header-mobile-menu-copiright">copyrights ©{{ new Date().getFullYear() }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileMenu',
  data(){
    return {
      selectedItem: 1,
      selectedItem2: 1,
      itemsNotSignin: [
        { text: 'Log in / Sign In', icon: 'user', showModal: true },
        { text: 'Travel Insights', icon: 'travel', to: this.$config.blogURL },
        { text: 'Cart', icon: 'cart', to: '/cart' },
        { text: 'Trips', icon: 'pin' },
        { text: 'Help', icon: 'help' },
      ],
      itemsSignin: [
        { text: 'My account', icon: 'user', subMenu : [
          { text: 'Profile', icon: 'cart', to: '/profile' },
          { text: 'My reservations', icon: 'cart', to: '/' },
          { text: 'Bookings', icon: 'cart', to: '/' },
          { text: 'Support', icon: 'cart', to: '/' },
          { text: 'Log out', icon: 'cart', to: '/' },
        ]
        },
        { text: 'Travel Insights', icon: 'travel', to: this.$config.blogURL },
        { text: 'Cart', icon: 'cart', to: '/cart' },
        { text: 'Trips', icon: 'pin' },
        { text: 'Help', icon: 'help' },
      ],
    }
  },
  computed: {
    userData() {
      return this.$store.state.userData
    },
  },
  mounted() {
    if (this.$config.blogURL) {
      this.itemsNotSignin.splice(findItemIndex('Travel Insights', this.itemsNotSignin), 1)
      this.itemsSignin.splice(findItemIndex('Travel Insights', this.itemsSignin), 1)
    }

    if (this.$config.enableCart) {
      this.itemsNotSignin.splice(findItemIndex('Cart', this.itemsNotSignin), 1)
      this.itemsSignin.splice(findItemIndex('Cart', this.itemsSignin), 1)
    }

    function findItemIndex(itemName, menuArray) {
      return menuArray.findIndex(item => item.text === itemName)
    }
  },
  methods: {
    checkClick($event){
      if ($event.to && this.$route.path === $event.to) {
        this.$emit('hideMenu', true)
      } else {
        this.ifLoginPress($event)
      }


    },
    ifLoginPress(item){
      if (item.showModal && item.text === 'Log in / Sign In'){
        this.$emit('showModal', true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header-mobile-menu {
  position: absolute;
  top: 56px;
  left: 0;
  background-color: #fff;
  width: 100%;
  height: calc(100vh - 55px);
  z-index: 1001;

  &__wrapper {
    padding: 24px;
    height: 100%;
  }
}
</style>
